// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-attributions-js": () => import("./../src/pages/attributions.js" /* webpackChunkName: "component---src-pages-attributions-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-form-submitted-js": () => import("./../src/pages/contact-form-submitted.js" /* webpackChunkName: "component---src-pages-contact-form-submitted-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-js": () => import("./../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-customers-js": () => import("./../src/pages/customers.js" /* webpackChunkName: "component---src-pages-customers-js" */),
  "component---src-pages-elections-evoting-system-js": () => import("./../src/pages/elections-evoting-system.js" /* webpackChunkName: "component---src-pages-elections-evoting-system-js" */),
  "component---src-pages-elections-online-voting-software-js": () => import("./../src/pages/elections-online-voting-software.js" /* webpackChunkName: "component---src-pages-elections-online-voting-software-js" */),
  "component---src-pages-elections-online-voting-system-js": () => import("./../src/pages/elections-online-voting-system.js" /* webpackChunkName: "component---src-pages-elections-online-voting-system-js" */),
  "component---src-pages-elections-online-voting-tool-js": () => import("./../src/pages/elections-online-voting-tool.js" /* webpackChunkName: "component---src-pages-elections-online-voting-tool-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lost-js": () => import("./../src/pages/lost.js" /* webpackChunkName: "component---src-pages-lost-js" */),
  "component---src-pages-nlive-es-js": () => import("./../src/pages/nlive_es.js" /* webpackChunkName: "component---src-pages-nlive-es-js" */),
  "component---src-pages-nlive-js": () => import("./../src/pages/nlive.js" /* webpackChunkName: "component---src-pages-nlive-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-political-neutrality-js": () => import("./../src/pages/political-neutrality.js" /* webpackChunkName: "component---src-pages-political-neutrality-js" */),
  "component---src-pages-pricing-js": () => import("./../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-process-js": () => import("./../src/pages/process.js" /* webpackChunkName: "component---src-pages-process-js" */),
  "component---src-pages-security-contact-js": () => import("./../src/pages/security-contact.js" /* webpackChunkName: "component---src-pages-security-contact-js" */),
  "component---src-pages-security-js": () => import("./../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-tos-js": () => import("./../src/pages/tos.js" /* webpackChunkName: "component---src-pages-tos-js" */),
  "component---src-pages-truly-secure-voting-system-js": () => import("./../src/pages/truly-secure-voting-system.js" /* webpackChunkName: "component---src-pages-truly-secure-voting-system-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

